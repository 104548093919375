import { ListParamsStrict, ListSharedResourceScope, ParamValueCondition } from './list-params';
import { BasicAsset, BasicEnvironment, BasicOrder, BasicTest, User } from './shared';
import { Values } from './type-utils';
import { Tenant } from './tenant';
import { AetherEnvironment } from './aether-environment';

export interface RegistrationList {
  readonly count: number;
  readonly rows: Registration[];
}

export const RegistrationType = {
  Certification: 'certification',
  Training: 'training',
} as const;
export type RegistrationType = Values<typeof RegistrationType>;

export const RegistrationStatus = {
  Active: 'active',
  Cancelled: 'cancelled',
  Pending: 'pending',
} as const;
export type RegistrationStatus = Values<typeof RegistrationStatus>;

export interface RegistrationEvent extends BasicAsset {
  parents?: BasicAsset[];
  tests?: BasicTest[];
  ownerId?: number;
  deletedAt?: string;
}

export interface InternalRegistration {
  aetherEnvironment?: AetherEnvironment | null;
  readonly createdAt: string;
  readonly deletedAt: string | null;
  endTime: string;
  environment: BasicEnvironment | null;
  event: RegistrationEvent | null;
  readonly id: number;
  invited: boolean;
  inviteRetries: number;
  order: BasicOrder | null;
  proctorId: number | null;
  startTime: string;
  status: RegistrationStatus;
  tenant?: Tenant | null;
  type: RegistrationType;
  readonly updatedAt: string;
  user: User;
}

export interface Registration {
  readonly id?: number;
  aetherEnvironment?: AetherEnvironment | null;
  aetherEnvironmentId?: AetherEnvironment['id'];
  type?: RegistrationType;
  status?: RegistrationStatus;
  startTime?: string;
  endTime?: string;
  proctorId?: number;
  invited?: boolean;
  inviteRetries?: number;
  tenant?: Tenant | null;
  tenantId?: Tenant['id'];
  event?: RegistrationEvent;
  eventId?: number;
  environment?: BasicEnvironment;
  environmentId?: number;
  order?: BasicOrder;
  orderId?: number;
  user?: User;
  userId?: number;
  readonly createdAt?: string;
  readonly updatedAt?: string;
  readonly deletedAt?: string | null;
}

export interface InternalListRegistrationsParams extends Omit<ListParamsStrict, 'all'> {
  aetherEnvironment?: boolean;
  complete?: boolean;
  endTime?: ParamValueCondition;
  eventId?: number[];
  eventName?: string;
  id?: number[];
  orderId?: number;
  proctorId?: number[];
  invited?: boolean;
  retrySortOrder?: boolean;
  scope?: RegistrationListScope;
  startTime?: ParamValueCondition;
  status?: RegistrationStatus;
  tenant?: boolean;
  trainingId?: number[];
  type?: RegistrationType;
  userEmail?: string;
  userRegion?: string;
  userType?: string;
  userId?: number[];
  includeInstructors?: boolean;
}

export interface ListRegistrationsParams
  extends Pick<
    InternalListRegistrationsParams,
    'attributes' | 'complete' | 'endTime' | 'eventId' | 'eventName' | 'id' | 'page' | 'limit' | 'order' | 'sort' | 'startTime' | 'type'
  > {}

export interface CreateRegistrationRequestBody {
  aetherEnvironmentId?: AetherEnvironment['id'] | null;
  endTime: string;
  environmentId?: number | null;
  eventId: number | null;
  orderId?: number | null;
  proctorId?: number | null;
  invited?: boolean;
  inviteRetries?: number;
  startTime: string;
  status: RegistrationStatus;
  tenantId?: Tenant['id'] | null;
  type: RegistrationType;
  userId: number;
}

export interface UpdateRegistrationRequestBody extends Partial<CreateRegistrationRequestBody> {
  deletedAt?: null;
}

export interface RescheduleRegistrationRequestBody {
  endTime: string;
  eventId: number;
  startTime: string;
}

export const RegistrationListScope = {
  All: ListSharedResourceScope.All,
  Archived: ListSharedResourceScope.Archived,
  Owned: ListSharedResourceScope.Owned,
} as const;
export type RegistrationListScope = Values<typeof RegistrationListScope>;
