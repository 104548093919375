import { Values } from './type-utils';

export const CertificationName = {
  DynatraceAssociateCertification: 'Dynatrace Associate Certification',
  DynatraceProfessionalCertification: 'Dynatrace Professional Certification',
  DynatraceMasterCertification: 'Dynatrace Master Certification',
  ServicesDeliveryObservability: 'Services Delivery Certification - Observability',
  ServicesDeliveryCloudOps: 'Services Delivery Certification - CloudOps',
  ServicesDeliverySaasUpgrade: 'Services Delivery Certification - SaaS Upgrade',
  AceServicesCertification: 'ACE Services Certification',
  AppDeveloperCertification: 'App Developer Certification',
  DynatraceAdvancedSecuritySpecialistCertification: 'Dynatrace Advanced Security Specialist Certification',
  DynatraceDemAndBizAnalyticsSpecialistCertification: 'Dynatrace DEM & Business Analytics Specialist Certification',
  DynatraceAdvancedAutomationSpecialistCertification: 'Dynatrace Advanced Automation Specialist Certification',
  DynatraceAdvancedObservabilitySpecialistCertification: 'Dynatrace Advanced Observability Specialist Certification',
  DynatracePartnerSales: 'Dynatrace Partner Sales',
  DynatracePartnerSalesSpecialist: 'Dynatrace Partner Sales Specialist',
  DynatracePartnerSalesEngineer: 'Dynatrace Partner Sales Engineer',
} as const;
export type CertificationName = Values<typeof CertificationName>;

export const Perform2024ExamName = {
  Perform2024DynatraceAssociateExam: 'Perform 2024 - Dynatrace Associate Exam',
  Perform2024DynatraceProfessionalWrittenExam: 'Perform 2024 - Dynatrace Professional Written Exam',
  Perform2024ServicesDeliveryObservability: 'Perform 2024 - Services Delivery Certification Exam - Observability',
} as const;
export type Perform2024ExamName = Values<typeof Perform2024ExamName>;

export const CertificationExamName = {
  DynatraceAssociateExam: 'Dynatrace Associate Exam',
  DynatraceProfessionalWrittenExam: 'Dynatrace Professional Written Exam',
  DynatraceProfessionalPracticalExam: 'Dynatrace Professional Practical Exam',
  ServicesDeliveryObservabilityExam: 'Partner Services Delivery Certification Exam - Observability',
  ServicesDeliveryCloudOpsExam: 'Partner Services Delivery Certification Exam - CloudOps',
  ServicesDeliverySaasUpgradeExam: 'Services Delivery Certification Exam - SaaS Upgrade',
  AppDeveloperExam: 'App Developer Certification Exam',
  DynatraceAdvancedSecuritySpecialistExam: 'Dynatrace Advanced Security Specialist Certification Exam',
  DynatraceDemAndBizAnalyticsSpecialistExam: 'Dynatrace DEM & Business Analytics Specialist Certification Exam',
  DynatraceAdvancedAutomationSpecialistExam: 'Dynatrace Advanced Automation Specialist Certification Exam',
  DynatraceAdvancedObservabilitySpecialistExam: 'Dynatrace Advanced Observability Specialist Certification Exam',
  DynatracePartnerSalesEngineerExam: 'Partner Sales Engineer Exam',

  ...Perform2024ExamName,
} as const;
export type CertificationExamName = Values<typeof CertificationExamName>;
